import filters from '@filters/filters';
export default {
  name: 'HouseListItem',
  mixins: [filters],
  components: {},
  data() {
    return {};
  },
  props: {
    detailInfo: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    }
  },
  computed: {},
  methods: {
    goDetail() {
      this.$emit('go-detail', this.detailInfo);
    },
    async initData() {}
  },
  filters: {},
  watch: {},
  created() {
    this.initData();
  },
  mounted() {}
};