var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-list-item cyui-list-multiple cyui-house-list-item",
    on: {
      "click": _vm.goDetail
    }
  }, [_c('div', {
    staticClass: "cyui-list-line"
  }, [_vm._m(0), _c('div', {
    staticClass: "cyui-list-content"
  }, [_c('div', {
    staticClass: "cyui-house-list-title"
  }, [_vm._v(_vm._s(_vm.detailInfo.projectName))]), _c('div', {
    staticClass: "cyui-house-list-brief"
  }, [_vm._v(_vm._s(_vm.detailInfo.roomNo))])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-list-thumb"
  }, [_c('span', {
    staticClass: "cyui-house-list-tag"
  }, [_vm._v("房号")])]);
}];
export { render, staticRenderFns };